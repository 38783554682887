import * as React from 'react';
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews';
import linkResolver from 'helpers/linkResolver';

import Layout from 'components/layout';

const PreviewPage = ({ isLoading, isPreview }) => {
  if (isLoading) return <Layout>Loading…</Layout>;

  if (isPreview === false) return <Layout>Not a preview</Layout>;

  return null;
};

export default withPrismicPreviewResolver(PreviewPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
    linkResolver,
  },
]);
